<template>
  <div class="write-hanzi-game-container border-layout">
    <div class="red-background-border"></div>

    <div class="write-hanzi-game-box">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @click.native="handleWrite"
      ></PageButton>
      <div
        class="write-hanzi-button"
        :class="{
          btnEyeHover: isBtnEyeHover,
          btnRestartHover: isBtnRestartHover,
        }"
      >
        <!-- 眼睛按钮 eye-->

        <div
          class="btn-item eye-btn"
          @click="handleClickEyeButton"
          @mouseenter="isBtnEyeHover = true"
          @mouseleave="isBtnEyeHover = false"
        >
          <img
            v-if="isShowTips"
            src="@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-on.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-off.svg"
            alt=""
          />
        </div>

        <!-- 重玩按钮 eye-->
        <div
          class="btn-item restart-btn"
          @click="restartGame('socket')"
          @mouseenter="isBtnRestartHover = true"
          @mouseleave="isBtnRestartHover = false"
        >
          <img
            v-if="!eyeIsHover"
            src="@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"
            alt=""
          />
        </div>
      </div>

      <div class="write-hanzi-game-content layout-red-bgc">
        <!-- 左边区域 -->
        <div class="write-game-content-left-box">
          <div class="blue-background-border">
            <div class="write-game-left-content">
              <div class="hanzi-write-area">
                <span class="pinyin font-pinyin-large">{{
                  hanziInfo.pinyin
                }}</span>
                <div class="hengIcon" v-if="currentStrokeNum == 0 || !isShowTips">
                  <img class="stroke-square-box" :src="hanziInfo.hanziBgImg" />
                </div>
                <div class="hengIcon-gif" v-if="isShowTips">
                  <img
                    class="stroke-square-box"
                    v-for="(item, index) in strokeStepImgList"
                    :key="index"
                    :src="item"
                    v-show="index + 1 === currentStrokeNum"
                  />
                </div>
                <div class="canvas-box" @click="handleSingleClick">
                  <KidsWhiteBoard v-if="showCanvas" />
                </div>
              </div>
              <div class="hanzi-stroke-tips-area" v-if="isShowTips">
                <img
                  class="templateOne"
                  :src="hanziInfo.strokeRemindImg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 右边彩虹桥 -->
        <div class="write-game-content-right-box">
          <div class="steps-img-box">
            <template v-for="(item, index) in rightImgList">
                <img :key="index" class="cloud" :src="item" :class="{ fadeOut: isFadeOut && index !== currentImgNum,fadeIn: isFadeIn && index === currentImgNum, isHidden: index > currentImgNum }" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  name: "WriteHanziPage",
  props: {
    rightImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    strokeStepImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    hanziInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      startWritingSocketInfo: {},
      restartScoket: {},
      singleStrokeScoket: {},
      showTipsScoket: {},

      numbers: "",
      eyeIsHover: false,
      isRestartGameHover: false,
      isShowTips: true,
      isBtnEyeHover: false,
      isBtnRestartHover: false,
      currentImgNum: 0, // 彩虹桥图片的index
      currentStrokeNum: 0, // 笔顺的的index
      showCanvas: false, // 使用canvas
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
      isFadeOut: false,
      isFadeIn: false,
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
  },
  watch: {
    startWritingSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000601,
          data: { value },
          text: "writeHanzidraw按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    singleStrokeScoket: {
      handler(value) {
        const data = {
          clickType: 2000602,
          data: { value },
          text: "writeHanzi写一笔按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    showTipsScoket: {
      handler(value) {
        const data = {
          clickType: 2000603,
          data: { value },
          text: "writeHanztips按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    restartScoket: {
      handler(value) {
        const data = {
          clickType: 2000604,
          data: { value },
          text: "writeHanzi restart按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("finishOneStroke", (val) => {
      this.currentImgNum = val;
      if (val === this.strokeStepImgList.length) {
        this.currentImgNum++;
      }
    });
    this.$bus.$on("clickDrawBtn", () => {
      this.handleWrite("socket", true);
    });
    this.$bus.$on("writeHanziSteps", () => {
      this.handleSingleClick("socket", true);
    });
    this.$bus.$on("clickTips", () => {
      this.handleClickEyeButton("socket", true);
    });
    this.$bus.$on("clickInit", () => {
      this.restartGame("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickDrawBtn");
    this.$bus.$off("writeHanziSteps");
    this.$bus.$off("clickTips");
    this.$bus.$off("clickInit");
    this.$bus.$off("finishOneStroke");
    this.$bus.$emit("startStrokePage", false);
  },
  methods: {
    initGame() {
      this.currentImgNum = 0;
      this.currentStrokeNum = 0;
      this.isShowTips = true;
      this.showCanvas = false;
      this.$bus.$emit("startStrokePage", false);
      this.isLastStep = false;
      setTimeout(() => {
        this.showCanvas = true;
        this.$bus.$emit("startStrokePage", true);
        this.currentStrokeNum++;
      }, 50);
    },
    restartGame(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.restartScoket = { index: Math.random() };
      }
      this.currentImgNum = 0;
      this.currentStrokeNum = 0;
      this.isShowTips = true;
      this.showCanvas = false;
      this.$bus.$emit("startStrokePage", false);
      this.isLastStep = false;
      setTimeout(() => {
        this.showCanvas = true;
        this.$bus.$emit("startStrokePage", true);
        this.currentStrokeNum++;
      }, 50);
    },
    handleClickEyeButton(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.showTipsScoket = { index: Math.random() };
      }
      this.isShowTips = !this.isShowTips;
    },
    handleSingleClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.singleStrokeScoket = { index: Math.random() };
      }

            //first trigger the fadeout
              this.isFadeIn = true;
              this.currentImgNum++;
            setTimeout(()=>{
              this.isFadeOut = true;
              },100);
              setTimeout(()=>{
                // this.isFadeOut = false;
                this.isFadeIn = false;
              },500)
      console.log("点击了");
      setTimeout(() => {
        this.currentStrokeNum++;
        // if (this.currentImgNum >= this.strokeStepImgList.length) {
        //   this.currentImgNum++;
        // }
        if (this.currentImgNum >= this.strokeStepImgList.length) {
          console.log("书写完成");
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
          this.currentStrokeNum = 0;
        }
      }, 500);
      this.socketInfo = { index: Math.random() };
    },
    // 开始书写
    handleWrite(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.startWritingSocketInfo = {
          index: Math.random(),
        };
      }
      console.log("开始书写");
      this.showCanvas = true;
      this.$bus.$emit("startStrokePage", true);
      setTimeout(() => {
        this.currentStrokeNum++;
      }, 1000);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.write-hanzi-game-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.write-hanzi-game-box {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: #fff;
  border-radius: 58px;
  z-index: 2;
  position: relative;
  .write-hanzi-button {
    width: 9%;
    position: absolute;
    right: 5%;
    bottom: 30%;
    z-index: 5;
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .btn-item {
      width: 100%;
      height: 50%;
      // padding: 1%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50px;
        padding: 20px 12px;
        cursor: pointer;
      }
    }
    .eye-btn img {
      padding-bottom: 10px;
    }
    .restart-btn img {
      padding-top: 10px;
    }
  }
  .btnEyeHover {
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background-top-hover.svg");
  }
  .btnRestartHover {
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background-bottom-hover.svg");
  }
  .restart-btn {
    bottom: 10px;
    right: 30px;
  }
}
.write-hanzi-game-content {
  background-color: #f7f4e0;
  border-radius: 58px;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .write-game-content-left-box {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .blue-background-border {
      margin-top: 10px;
      margin-left: 10px;
      width: calc(40% - 10px);
      height: calc(50% - 10px);
      position: absolute;
      // left: 14%;
      // top: 13%;
      background: #224e96;
      border-radius: 44px;
      min-height: 500px;
      max-width: 400px;
      max-height: 600px;
      .write-game-left-content {
        color: #000;
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 44px;
        top: -10px;
        left: -10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;
        min-height: 500px;
        max-width: 400px;
        max-height: 600px;
        .hanzi-write-area {
          width: 70%;
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .pinyin {
            position: absolute;
            top: 3%;
            color: #cd4c3f;
          }
          .hengIcon,
          .hengIcon-gif {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            img {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              border-radius: 4px;
            }
          }
          .hengIcon {
            img {
              width: 100%;
            }
          }
          .stroke-square-box {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            img {
              width: 100%;
              // height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
            }
          }
          .stroke-square-box {
            cursor: pointer;
            max-width: 250px;
          }

          div {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
          }
          img {
            width: 100%;
          }
        }
        .hanzi-stroke-tips-area {
          width: 70%;
          height: 10%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 10%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .write-game-content-right-box {
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
    .steps-img-box {
      width: 85%;
      height: 80%;
      display: flex;
      position: relative;
      // align-items: center;
      // justify-content: flex-start;
      .img-item {
        width: 80%;
        display: flex;
      }

      .cloud {
        // width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .action-button-box {
      width: 90px;
      height: 180px;
      // width:20%;
      // height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 88px;
      right: 5px;
      z-index: 100;
      .backgroundImg {
        width: 60%;
        position: absolute;
        right: 20%;
        bottom: 20%;
      }
      .action-button-box-top,
      .action-button-box-bottom {
        z-index: 9999;
        width: 60%;
        img {
          width: 80%;
        }
      }
    }
  }
}
</style>
